<template>
	<div class="device-unkeep">
		<div class="table-box">
			<div class="title">
				<span class="icon">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<el-button class="btn" @click="addBtnClick()" type="success" icon="el-icon-plus">
				添 加
			</el-button>
			<!-- <el-table
				v-else
				class="table2"
				:data="tableData"
				ref="table"
				height="150"
				:row-class-name="tableRowClassName"
				:height="tableHeight"
				:header-row-style="{height: '50px'}"
				:row-style="{height: '50px'}">
				<el-table-column label="名称" prop="name" min-width="70%"></el-table-column>
				<el-table-column label="操作" min-width="30%" align="center">
					<template slot-scope="scope">
						<el-button @click="watchBtnClick(scope.row.fileUrl)" size="mini" type="primary" icon="el-icon-view">
							查 看
						</el-button>
					</template>
				</el-table-column>
			</el-table> -->
			<el-table
				ref="table"
				class="table"
				style="width: 100%"
				:data="upkeepRecordList"
				:row-class-name="tableRowClassName"
				max-height="650"
			>
				<el-table-column label="设备序列号" min-width="13%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.equipmentNo | format}}</p>
					</template>
				</el-table-column>
				<el-table-column label="设备名称" min-width="12.5%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.equipmentName | format}}</p>
					</template>
				</el-table-column>
				<el-table-column label="保养日期" min-width="12.5%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.createTime | format}}</p>
					</template>
				</el-table-column>
				<el-table-column label="保养方案" min-width="12.5%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.upkeepName | format}}</p>
					</template>
				</el-table-column>
				<el-table-column label="完成状态" min-width="12%" align='center'>
					<template slot-scope="scope">
						<el-tag :type="scope.row.upkeepStatus === '1' ? 'info' : 'primary'">
							{{scope.row.upkeepStatus | formatStatus}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="保养人" min-width="12.5%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.upkeepPerson | format}}</p>
					</template>
				</el-table-column>
				<el-table-column label="备注" min-width="12%" align='center'>
					<template slot-scope="scope">
						<p>{{scope.row.remark | format}}</p>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<el-dialog title="添加保养信息" :visible.sync="dialogFormVisible" width="50%" center>
			<el-form 
				:model="upkeepForm"
				ref="upkeepForm"
				style="width: 400px; margin: 0 auto;"
				label-position="left">
				<el-form-item label="设备ID" :label-width="formLabelWidth" class="form-item-box">
					<el-input v-model="upkeepForm.equipmentId" placeholder="0" readonly></el-input>
				</el-form-item>
				<el-form-item label="设备名称" :label-width="formLabelWidth" class="form-item-box">
					<el-input v-model="upkeepForm.equipmentName" placeholder="请输入设备名称" readonly></el-input>
				</el-form-item>
				<el-form-item label="保养日期" :label-width="formLabelWidth" class="form-item-box">
					<el-date-picker
					    v-model="upkeepForm.createTime"
					    type="datetime"
						value-format="yyyy-MM-dd HH:mm:ss"
					    placeholder="选择日期时间"
						style="width: 280px;"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="保养方案" :label-width="formLabelWidth" class="form-item-box">
					<el-input v-model="upkeepForm.upkeepName" placeholder="请输入保养方案" readonly></el-input>
				</el-form-item>
				<el-form-item label="保养状态" :label-width="formLabelWidth" class="form-item-box">
					<el-select v-model="upkeepForm.upkeepStatus" placeholder="请选择" style="width: 280px;">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="保养人" :label-width="formLabelWidth" class="form-item-box">
					<el-input v-model="upkeepForm.upkeepPerson" placeholder="请输入保养人"></el-input>
				</el-form-item>
				<el-form-item label="添加人" :label-width="formLabelWidth" class="form-item-box">
					<el-input v-model="upkeepForm.createdBy" placeholder="请输入添加人"></el-input>
				</el-form-item>
				<el-form-item label="备注" :label-width="formLabelWidth" class="form-item-box">
					<el-input type="textarea" :rows="4" v-model="upkeepForm.remark" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirmBtnClick()">确 定</el-button>
				<el-button @click="dialogFormVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getUpkeepRecordList,
		getUpkeepValue,
		addUpkeepRecord,
		getUnkeepHour
	} from 'network/deviceUnkeep'
	export default {
		name: "deviceUnkeep",
		data() {
			return {
				tableHeight: 500,
				upkeepRecordList: [],
				dialogFormVisible: false,
				userName: sessionStorage.getItem("userName"),
				userId: sessionStorage.getItem("userId"),
				upkeepForm: {
					equipmentId: "",
					equipmentName: "",
					createTime: "",
					upkeepId: "",
					upkeepName: "",
					upkeepStatus: "",
					upkeepPerson: "",
					createdBy: "",
					remark: ""
				},
				formLabelWidth: '120px',
				options: [
					{
				        value: '1',
				        label: '是'
				    },
					{
				        value: '0',
				        label: '否'
				    },
				]
			}
		},
		created() {
			this.getUpkeepRecordList1()
			this.getUnkeepHour1()
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		filters: {
			format(val) {
				return val == null ? "--" : val
			},
			formatStatus(val) {
				return val == 0 ? "否" : "是"
			}
		},
		methods: {
			open1() {
				this.$notify({
					title: '提示',
					message: '这是一条不会自动关闭的消息',
					duration: 0
				});
			},
			getUpkeepRecordList1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId"),
					userId: this.userId
				}
				getUpkeepRecordList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败")
					this.upkeepRecordList = res.data.data
				})
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			addBtnClick() {
				this.dialogFormVisible = true
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getUpkeepValue(obj).then(res => {
					// console.log(res)
					if(res.data.code !== 0)
					return  this.$message.error("请求数据失败")
					this.upkeepForm.equipmentId = res.data.data.id
					this.upkeepForm.equipmentName = res.data.data.equipmentName
					this.upkeepForm.upkeepId = res.data.data.upkeepId
					this.upkeepForm.upkeepName = res.data.data.upkeepName
					this.upkeepForm.createdBy = this.userName
				})
			},
			confirmBtnClick() {
				let obj = {
					equipmentId: this.upkeepForm.equipmentId,
					equipmentName: this.upkeepForm.equipmentName,
					calendar: this.upkeepForm.createTime,
					upkeepId: this.upkeepForm.upkeepId,
					upkeepStatus: this.upkeepForm.upkeepStatus,
					upkeepPerson: this.upkeepForm.upkeepPerson,
					createBy: this.upkeepForm.createdBy,
					remark: this.upkeepForm.remark
				}
				// console.log("b"+obj.calendar)
				addUpkeepRecord(obj).then(res => {
					if(res.data.code == 0) {
						this.$message.success("操作成功")
						this.dialogFormVisible = false
						this.getUpkeepRecordList1()
					}else {
						this.$message.error("操作失败")
					}
				})
			},
			//保养提醒
			getUnkeepHour1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getUnkeepHour(obj).then(res => {
					if(res.data.code == 0) {
						// console.log(res)
						var time = this.formatUnkeep(res.data.type, res.data.upkeepWarn)
						// console.log("xx"+time)
					}
				})
			},
			//格式化保养时间
			formatUnkeep(type, unkeepTime) {
				// var unkeepT = '';
				if(type == 1 || type == 3){
					return unkeepTime
				}else if(type == 2){
					return parseInt(unkeepTime)
				}
			}
		}
	}
</script>
<style>
	/* 表格隔行变色颜色 */
	.el-table .success-row {
		background: rgba(244,246,247,1);
	}
	/*表格字体*/
	.el-table {
		font-size: 1.375rem !important;
	}
	/*表头样式*/
	.el-table th {
		background: rgba(245,247,247,1) !important;
		font-size: 1.375rem !important;
		font-family: PingFang SC !important;
		font-weight: 500 !important;
		color: rgba(58,132,171,1) !important;
	}
	.el-table td, .el-table th {
		height: 3.75rem !important;
		padding: 0 !important;
	}
	.el-table .cell {
		padding: 0.625rem 0 !important;
	}
	.form-item-box label {
		font-size: 1.125rem !important;
		color: #303133;
	}
</style>
<style lang="scss" scoped>
.device-unkeep {
	width: 100%;
	height: 100%;
	overflow: hidden;
	// overflow-y: scroll;
	border-radius: 0.625rem;
	box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.table-box {
		width: 100%;
		.btn {
			position: absolute;
			top: 0.5rem;
			right: 0.625rem;
		}
		.table {
			border-top: 1px solid #EBEEF5;
			overflow-y: scroll;
			overflow: hidden;
		}
	}
}
</style>
