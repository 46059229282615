import instance from './request'

//获取设备保养记录
export function getUpkeepRecordList(dataObj) {
  return instance({
    url: 'equipment/upkeepRecordList',
    method: 'POST',
    params: dataObj
  })
}

//获取设备保养记录
export function getUpkeepValue(dataObj) {
  return instance({
    url: 'equipment/resumeFile',
    method: 'GET',
    params: dataObj
  })
}

//添加设备保养记录
export function addUpkeepRecord(dataObj) {
  return instance({
    url: 'equipment/addUpkeepRecord',
    method: 'POST',
    params: dataObj
  })
}

//添加设备保养记录
export function getUnkeepHour(dataObj) {
  return instance({
    url: 'equipment/upkeepWarn',
    method: 'POST',
    params: dataObj
  })
}

//设备保养提醒

export function upkeepWarn(dataObj) {
  return instance({
    url: 'equipment/upkeepWarn',
    method: 'POST',
    params: dataObj
  })
}